/* eslint-disable no-restricted-imports */
// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import Table from "react-bootstrap/Table";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";

export function MyPage2() {
  const user = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [classifica, setClassifica] = useState([]);

  const classificaFetch = async () => {
    setLoading(true);
    const res = await fetch(`https://test.fantacarnevaledipalmacampania.it/FantaCarnevale/api/classifica`);
    const data = await res.json();
    setLoading(false);
    setClassifica(data);
  };
  console.log(user);
  useEffect(() => {
    classificaFetch();
  }, []);


  console.log(classifica)
  // console.log(classifica.length = [])

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Nome Quadriglia</th>
            <th>Nome Utente</th>
            <th>Score</th>
          </tr>
        </thead>
     

        {/* <tbody> */}
          <>
            {/* <tr>
              {classifica.map((e) => (
                <td>{e.first_team.nome_quadriglia}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.first_team.nome_user}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.first_team.total_score}</td>
              ))}
            </tr> */}
              {/**second */}
            {/* <tr>
              {classifica.map((e) => (
                <td>{e.second_team.nome_quadriglia}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.second_team.nome_user}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.second_team.total_score}</td>
              ))}
            </tr> */}
              {/**terz */}
            {/* <tr>
              {classifica.map((e) => (
                <td>{e.terz_team.nome_quadriglia}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.terz_team.nome_user}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.terz_team.total_score}</td>
              ))}
            </tr> */}
              {/** quart */}
            {/* <tr>
              {classifica.map((e) => (
                <td>{e.quart_team.nome_quadriglia}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.quart_team.nome_user}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.quart_team.total_score}</td>
              ))}
            </tr>  */}
              {/**quint */}
            {/* <tr>
              {classifica.map((e) => (
                <td>{e.quint_team.nome_quadriglia}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.quint_team.nome_user}</td>
              ))}
              {classifica.map((e) => (
                <td>{e.quint_team.total_score}</td>
              ))}
            </tr> */}
          </>
          
        {/* </tbody> */}
      </Table>
      <h1 className="text-center">All'inizio dei giochi del FantaCarnevale verrà esposta la classifica</h1>
    </>
  );
}
